<template>
  <base-section id="about-our-product">
    <base-section-heading
      icon="mdi-vuetify"
      title="COMPAC"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
          text-justify
        >
          <base-info-card v-bind="card" />
          <v-btn>Ir a Certificación</v-btn>
        </v-col>
      </v-row>

    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Certificación',
          subtitle: '',
          text: 'Es el proceso de evaluación a que voluntariamente se somete un médico después de haber cursado y aprobado un programa de especialización (residencia médica avalada por una institución de enseñanza superior) en alguna rama de la medicina.',
          callout: '01',
        },
        {
          title: 'Recertificación',
          subtitle: '',
          text: 'Es el proceso de evaluación a un médico especialista certificado en alguna rama de la medicina que permite razonablemente asegurar que ha continuado con su preparación, que mantiene al día sus conocimientos y que sabe utilizar la tecnología para atender eficazmente y con calidad a sus pacientes.',
          callout: '02',
        },
        {
          title: 'Educación Continua',
          subtitle: '',
          text: 'Este Comité tiene como misión esencial el coadyuvar en la labor conjunta del Consejo Mexicano de Patología Clínica y Medicina de Laboratorio en todas aquellas actividades académicas que favorezcan la actualización de todos aquellos miembros en los diferentes temas de la especialidad.',
          callout: '03',
        },
      ],
    }),
  }
</script>
